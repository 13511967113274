<template>
  <!-- <div class="notes-table" style="border: 1px solid red"> -->

  <div class="d-flex flex-column flex-nowrap fill-height" :class="{'hide-row-drag': !sidebarOpen}">
    <NotesFilterBar />
    <div class="flex-grow-1 overflow-hidden notes-grid-wrapper notes-table">
      <DataTable
        :list="notes"
        :columnsList="columns"
        ref="dataGrid"
        :options="options"
        :renderGroupCaption="createGroupItemsCaptions"
        :allowDragAndDrop="allowDragAndDrop"
        @edit:enabled="handleEditEnabled"
        :whiteListBtns="[
          'add-resource-pop-up',
          'resource-type-selector',
          'resource-type-cate-selector',
          'resource-save-btn',
          'resource-title-editor-wrapper',
          'resource-error-pop-up',
          'v-overlay',
          'note-topic-form',
        ]"
        :collapsedGroupsList="collapsedGroupsList"
        @row:added="handleRowAdd"
        @row:updated="handleRowUpdated"
        @rows:selected="handleRowsSelected"
        @filter="handleFilterSuccess"
        @list:processed="handleListProcessed"
        @group:toggled="handleGroupToggle"
        @key-pressed:delete="handleDeleteCall"
        @keydown="handleKeydown"
        :itemSize="36"
      />
    </div>
  </div>
  <!-- </div> -->
</template>
<script>
import DataTable from "@/core/components/DataTable/DataTable.vue";
import NotesFilterBar from "@/components/NotesFilterBar/NotesFilterBar.vue";
import EventEmitter from "@/helpers/eventEmitter";
import ActionsVue from "./components/NoteActionsCell.vue";
import PathSelector from "./components/PathSelectorCell.vue";
import Path from "./components/PathCell.vue";
import NoteSaveBtnCell from "./components/NoteSaveCell.vue";
import userDetailsMixin from "@/mixins/userDetailsMixin";
import notesHelpersMixin from "@/mixins/notesHelpersMixin";
import TitleViewerCell from "@/components/GridComponents/TitleViewerCell.vue";
import RowHandler from "@/components/GridComponents/RowHandlerCell.vue";
import {
  ADD_NOTE_EVENT,
  CLOSE_INLINE_ADD,
  REFRESH_NOTES_LIST,
  REGROUP_NOTES_EVENT,
  SET_ID_FOR_SCROLL,
} from "@/variables/events";
import {
  createFoldersFilter,
  createUpdateAndEditedNoteDataByCell,
  createNotesGroupCaptions,
  createNotesSearchFilter,
  processNotes,
  addOrRemoveOrUpdateNotesInLocalList,
  getUserNotes,
  processNote,
} from "@/helpers/notes";
import { mapActions, mapGetters, mapState } from "vuex";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import { navViewFiltersConfig } from "@/variables/viewConfigs";
import { groupOpts, NOTE_PROPS_FOR_FORM, sortOpts } from "@/variables/notes";
import NoteTypeSelectorCell from "./components/NoteTypeSelectorCell.vue";
import NoteTypeCell from "./components/NoteTypeCell.vue";
import DateCell from "@/components/GridComponents/DateCell.vue";
import { checkIfRowDataHasChanged } from "@/helpers/common";
import { convertValueToArray } from "@/utils/array";
import { NEW_DARK_ICON_COLOR } from "@/variables/colors";
import isEscKey from "@/utils/isEscKey";
export default {
  mixins: [userDetailsMixin, notesHelpersMixin],
  data(vm) {
    return {
      createdFilters: [],
      allowDragAndDrop: false,
      columns: [
        {
          frozen: true,
          width: 0,
          minWidth: 0,
          cssClass: "intend-cell",
          field: "handler",
          sortable: false,
          editable: false,
        },

        {
          rowHandle: true,
          frozen: true,
          width: 30,
          minWidth: 30,
          cssClass: "row-handler-cell",
          CellComponent: RowHandler,
          field: "row-handler",
          sortable: false,
          extraData: {
            useDynamicColor: true,
            colorProvider: () => {
              return NEW_DARK_ICON_COLOR;
              // return getTaskRowHandlerColor(cell.data.priority);
            },
          },
          editable: false,
        },
        {
          title: "Type",
          field: "itemType",
          resizable: false,
          editable: true,
          vertAlign: "middle",
          CellEditor: NoteTypeSelectorCell,
          CellComponent: NoteTypeCell,
          width: 38,
        },
        {
          field: "actions",
          width: 32,
          resizable: false,
          sortable: false,
          editable: false,
          visible: true,
          CellComponent: ActionsVue,
        },

        {
          title: "Title",
          field: "title",
          resizable: false,
          editable: true,
          primary: true,
          vertAlign: "middle",
          CellEditor: TitleViewerCell,
          CellComponent: TitleViewerCell,
          extraData: {
            placeholder: "Enter note title",
            watchResources: true,
            previousCell: "itemType",
          },
          cellEdited: vm.cellEdited,
          onDataUpdate: vm.onDataUpdate,
          width: "stretch",
          minWidth: 300,
          expand: true,
        },
        {
          title: "Topic",
          field: "path",
          resizable: false,
          editable: true,
          width: 138,
          CellComponent: Path,
          CellEditor: PathSelector,
          vertAlign: "middle",
          cellEdited: vm.cellEdited,
          tristateSort: true,
          startSortDir: "asc",
          onDataUpdate: vm.onDataUpdate,
        },
        {
          title: "",
          field: "hashedPath",
          resizable: false,
          visible: false,
        },
        {
          title: "Modified",
          field: "modifiedAsDate",
          CellComponent: DateCell,
          resizable: false,
          editable: false,
          visible: true,
          width: 135,
        },
        {
          title: "Created",
          field: "createdAsDate",
          resizable: false,
          CellComponent: DateCell,
          CellEditor: NoteSaveBtnCell,
          visible: true,
          width: 135,
        },
        {
          title: "Created",
          field: "created",
          resizable: false,
          visible: false,
        },
        {
          title: "Modified",
          field: "modified",
          resizable: false,
          visible: false,
        },
      ],
      options: {},
    };
  },
  components: {
    DataTable,
    NotesFilterBar,
  },
  methods: {
    handleKeydown(e) {
      if (isEscKey(e)) {
        const isGridEditingActive = this.$refs.dataGrid.isEditingActive();
        if (isGridEditingActive) {
          e.stopImmediatePropagation();
          e.stopPropagation();
          e.preventDefault();
        }
      }
    },
    handleDeleteCall() {
      if (isEmpty(this.selectedNotes)) return;

      const clonedNotes = cloneDeep(this.selectedNotes);
      this.$refs.dataGrid.deselectPreviousRows();
      this.removeNotesFromNotesList(this.selectedNotes);
      addOrRemoveOrUpdateNotesInLocalList({
        notesToRemove: [...clonedNotes],
      });
    },
    handleEditEnabled(_, rowData) {
      this.currEditedRow = rowData;
    },
    async scrollToSelectedNoteOrEditedNote() {
      let rowKeyToUse;
      if (!isEmpty(this.currEditedRow)) {
        rowKeyToUse = this.currEditedRow.key;
      }

      if (
        !rowKeyToUse &&
        !isEmpty(this.selectedNotes) &&
        this.selectedNotes.length === 1
      ) {
        rowKeyToUse = this.selectedNotes[0].key;
      }

      if (rowKeyToUse) {
        const storedRow = this.filteredNotes.find((t) => t.key === rowKeyToUse);

        if (!isEmpty(storedRow)) {
          const currGroupMode = this.$refs.dataGrid.getGroupBy();

          const currGroupValue = storedRow[currGroupMode];
          this.$refs.dataGrid.toggleGroup(
            {
              key: currGroupMode,
              value: currGroupValue,
            },
            "remove"
          );
          await this.$nextTick();
          this.$refs.dataGrid.checkAndScrollToRow(storedRow.key);
        }
      }
    },
    handleGroupToggle(groupData, mode) {
      const currCollapsedGroups = cloneDeep(this.collapsedGroupsList);
      const storedIndex = currCollapsedGroups.findIndex((g) =>
        isEqual(g, groupData)
      );

      if (mode) {
        if (mode === "remove") {
          if (storedIndex >= 0) {
            currCollapsedGroups.splice(storedIndex, 1);
          }
        }

        if (mode === "add") {
          if (storedIndex === -1) {
            currCollapsedGroups.push({ ...groupData });
          }
        }
      } else {
        if (storedIndex >= 0) {
          currCollapsedGroups.splice(storedIndex, 1);
        } else {
          currCollapsedGroups.push({ ...groupData });
        }
      }

      this.updateCollapsedGroupListInDb(currCollapsedGroups);
    },
    updateCollapsedGroupListInDb(list) {
      getUserNotes().set(list, "collapsedGroups");
    },
    handleListProcessed(list = []) {
      this.updateNoteState({
        filteredNotes: list,
      });
    },
    handleFilterSuccess({ count }) {
      this.updateNoteState({
        notesCount: count,
      });
    },
    async cellEdited(cell) {
      const fieldName = cell.getField();
      const oldValue = cell.getOldValue();
      const row = cell.getRow();
      const currRowData = row.getData();
      const rowId = row.getIndex();
      const currValue = cell.getValue();
      if (oldValue !== currValue) {
        const { updates, editedData } = createUpdateAndEditedNoteDataByCell({
          fieldName,
          oldValue,
          rowId,
          currValue,
          currRowData,
        });

        this.updateMultiNotesInNotesList({ updates, editedData, key: rowId });
      }
    },
    async updateNotesInList(notesToUpdate, setForScroll) {
      if (!Array.isArray(notesToUpdate)) notesToUpdate = [notesToUpdate];
      addOrRemoveOrUpdateNotesInLocalList(
        {
          notesToUpdate,
        },
        false
      );

      if (setForScroll) {
        this.setNoteIdForScroll(notesToUpdate[0].key);
      }
      await this.$nextTick();
      this.refreshList();
    },
    async onDataUpdate(rowId, fieldName, value, cell) {
      if (!rowId) return;
      const row = cell.getRow();
      const currRowData = row.getData();
      const { updates, editedData } = createUpdateAndEditedNoteDataByCell({
        fieldName,
        currValue: value,
        rowId,
        oldValue: currRowData[fieldName],
        currRowData,
      });
      this.updateMultiNotesInNotesList({ updates, editedData, key: rowId });
      this.updateNotesInList(
        [
          {
            key: currRowData.key,
            updates,
          },
        ],
        true
      );
      // add method to update note in vue
    },
    addMissingValuesInSortOpts(sortOpt) {
      const sortBy = sortOpt.map((sort) => ({
        ...sort,
        missingValue: "last",
      }));
      return sortBy;
    },
    createTable() {
      const { sortOpt, groupOpt } = this.groupAndSortTasksConfig;

      const addedSortOpts = this.addMissingValuesInSortOpts(sortOpt);
      const filters = cloneDeep(this.createCurrNavFilters());
      this.options = {
        ...this.options,
        sortBy: addedSortOpts,
        groupBy: groupOpt || "",
      };
      this.createdFilters = [...filters];
    },
    addNewNote(data = {}) {
      this.$refs.dataGrid.addNewRow({ ...data });
    },
    updateSelectedRows(selectedRows) {
      this.setSelectedNotes(selectedRows);
    },
    addFolderFilters(list, currFilters) {
      let filters = cloneDeep(currFilters);
      const createdFilters = [...createFoldersFilter(list, this.notesTree)];
      filters[0] = [...filters, ...createdFilters];
      // const existingFilterIndex = filters.findIndex((filter) =>
      //   isEqual(filter, createdFilters)
      // );
      // if (existingFilterIndex === -1) {
      //   filters.push(createdFilters);
      // }

      // if (list && list.length) {
      //   filters.push([...createProjectsOrCategoriesFilters(list)]);
      // }

      return filters;
    },

    createCurrNavFilters() {
      let currFilters = cloneDeep(this.navFilter);
      const selectedFolders = this.selectedFolders;
      const selectedNoteTopics = this.selectedNoteTopics;

      const selectedTopicIds = !isEmpty(selectedFolders)
        ? selectedFolders
        : selectedNoteTopics;
      let searchQuery = this.searchQuery;
      searchQuery = searchQuery && searchQuery.trim();
      if (!isEmpty(selectedTopicIds)) {
        currFilters = this.addFolderFilters(selectedTopicIds, currFilters);
      }

      if (searchQuery && searchQuery !== "*") {
        currFilters.push([...createNotesSearchFilter(searchQuery)]);
      }
      return currFilters;
    },
    updateFilters() {
      const filters = this.createCurrNavFilters();
      this.createdFilters = filters;
    },
    async setUpdatedFiltersInGrid() {
      const filters = cloneDeep(this.createdFilters);
      this.options = {
        ...this.options,
        filters: filters,
      };
      await this.$nextTick();
      if (this.$refs.dataGrid) {
        const notes = this.$refs.dataGrid.getSelectedRows();
        this.setSelectedNotes(notes);
      }
    },
    createGroupItemsCaptions(data, group) {
      return createNotesGroupCaptions(data, group);
    },
    async handleRowAdd(data) {
      const processedNoteData = processNote(data);

      addOrRemoveOrUpdateNotesInLocalList(
        {
          notesToAdd: [processedNoteData],
        },
        false
      );

      this.setNoteIdForScroll(processedNoteData.key);
      await this.$nextTick();
      this.refreshList();
    },
    setNoteIdForScroll(noteIds, refreshList) {
      this.$refs.dataGrid.setRowIdsForScroll(convertValueToArray(noteIds));

      if (refreshList) {
        this.refreshList();
      }
    },
    handleRowsSelected(rows) {
      this.setSelectedNotes(rows);
    },
    async focusOnSelectedItem(selectedItemKey, select, noScroll) {
      await this.$nextTick();
      if (selectedItemKey) {
        this.$refs.dataGrid.scrollToRow(
          selectedItemKey,
          select,
          true,
          noScroll
        );
      }
    },
    handleRowUpdated({ rowId, updatedRowData: editedRowData }) {
      if (!rowId || isEmpty(editedRowData)) return;

      const currNoteData = this.notes.find((n) => n.key === rowId);
      const isDataChanged = checkIfRowDataHasChanged(
        editedRowData,
        currNoteData,
        NOTE_PROPS_FOR_FORM
      );

      if (!isDataChanged) {
        return;
      }
      this.updateNotesInList([
        {
          key: rowId,
          updates: editedRowData,
        },
      ]);
    },
    async handleNotesRefresh(reprocessTasks) {
      if (reprocessTasks) {
        const currAllDirs = this.directories;
        this.updateNoteState({
          notes: processNotes(this.rawNotes, currAllDirs),
        });
      }
      await this.$nextTick();
      this.refreshList();
    },
    refreshList() {
      const createdFilters = this.createCurrNavFilters();
      if (!isEqual(createdFilters, this.createdFilters)) {
        this.updateFilters();
      } else {
        this.$refs.dataGrid.setup();
      }
    },
    closeInlineAdd() {
      this.$refs.dataGrid.cancelAddRow();
    },
    getCurrEditingRowId() {
      return this.$refs.dataGrid.getCurrEditingRow();
    },
    isGridInEditMode() {
      return this.$refs.dataGrid.isEditingActive();
    },
    ...mapActions("note", ["setSelectedNotes", "updateNoteState"]),
  },
  computed: {
    ...mapState(["sidebarOpen"]),
    ...mapGetters("note", [
      "notes",
      "selectedFolders",
      "searchQuery",
      "directories",
      "notesTree",
      "rawNotes",
      "collapsedGroupsList",
      "selectedNotes",
      "filteredNotes",
      "notesCount",
      "selectedNoteTopics",
    ]),
    navFilter() {
      return navViewFiltersConfig[4].filters;
    },
    groupAndSortTasksConfig() {
      const sortOpt = sortOpts[0];
      const groupOpt = groupOpts[0];
      return {
        sortOpt,
        groupOpt,
      };
    },
  },
  watch: {
    selectedFolders(n, o) {
      if (!isEqual(n, o)) {
        this.updateFilters();
      }
    },
    selectedNoteTopics: {
      handler(n, o) {
        if (!isEqual(n, o)) {
          this.updateFilters();
        }
      },
      deep: true,
    },
    createdFilters: {
      handler(newCreatedFilters, oldCreatedFilters) {
        if (
          !isEqual(newCreatedFilters, oldCreatedFilters) ||
          isEmpty(newCreatedFilters)
        ) {
          this.setUpdatedFiltersInGrid();
        }
      },
      deep: true,
    },
    searchQuery() {
      this.updateFilters();
    },
  },
  mounted() {
    this.createTable();
    EventEmitter.on(ADD_NOTE_EVENT, this.addNewNote);
    EventEmitter.on(REFRESH_NOTES_LIST, this.handleNotesRefresh);
    EventEmitter.on(CLOSE_INLINE_ADD, this.closeInlineAdd);
    EventEmitter.on(SET_ID_FOR_SCROLL, this.setNoteIdForScroll);
  },
  beforeDestroy() {
    EventEmitter.off(ADD_NOTE_EVENT, this.addNewNote);
    EventEmitter.off(CLOSE_INLINE_ADD, this.closeInlineAdd);
    EventEmitter.off(REGROUP_NOTES_EVENT, this.reGroupNotes);
    EventEmitter.off(SET_ID_FOR_SCROLL, this.setNoteIdForScroll);
  },
};
</script>
<style lang="scss">
.notes-table .datagrid-cell.title-cell > div {
  line-height: 1.55 !important;
  // padding-top: 7px;
  // padding-bottom: 7px;
  // white-space: pre-wrap;
  overflow: hidden;
  height: 33px;
  // height: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  // margin-top: 7px;
  // margin-bottom: 7px;
}
.notes-table .datagrid-row.row-selected .datagrid-cell.expand.title-cell > div {
  line-height: 1.55 !important;
  white-space: pre-line !important;
  max-height: max-content !important;
  height: auto !important;
  display: block;
  // padding-top: 0px !important;
  // padding-bottom: 0px !important;
  // padding-top: 7px !important;
  // padding-bottom: 7px !important;
}
.notes-table .datagrid-row.row-selected .datagrid-cell.expand.title-cell > div {
  line-height: 1.55 !important;
  white-space: pre-line !important;
  max-height: max-content !important;
  height: auto !important;
  display: block;
  // padding-top: 0px !important;
  // padding-bottom: 0px !important;
  // padding-top: 7px !important;
  // padding-bottom: 7px !important;
}
.datagrid-row[role="row"]:hover .edit-note-btn {
  display: inline-block;
}

.datagrid-header-cell.itemType-cell {
  border: 0 !important;
}

.notes-table .datagrid-row[role="row"].row-selected {
  background: var(--selection-color) !important;

  // .datagrid-cell {
  //   background: none;
  // }
  // .intend-cell {
  //   background: #fff;
  // }
  .datagrid-cell:not(.intend-cell) {
    background: none;
    // border-top: 1px solid var(--primary-color) !important;
    // border-bottom: 1px solid var(--primary-color) !important;
  }
  .intend-cell {
    background: #fff;
  }
  // .row-handler-cell {
  //   // border-left: 1px solid var(--primary-color) !important;
  // }
}

.notes-table .datagrid-row[role="row"]:hover:not(.row-editing) {
  // background: none !important;

  // .datagrid-cell {
  //   background: none !important;
  // }

  // .datagrid-group {
  //   background: none !important;
  // }
  .intend-cell {
    background: #fff !important;
  }
  .datagrid-cell:not(.intend-cell) {
    // background: none !important;
    border-top: 1px solid var(--primary-color) !important;
    border-bottom: 1px solid var(--primary-color) !important;
  }
  .intend-cell {
    background: #fff;
  }
  .row-handler-cell {
    border-left: 1px solid var(--primary-color) !important;
  }
  // .priority-cell {
  //   width: 70px !important;
  // }
}

.notes-table .actions-cell .actions-cell-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4px;
}

.notes-grid-wrapper {
  margin-top: 6px;
}
</style>
